import {Injectable, ViewContainerRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject} from 'rxjs';
import {distinctUntilChanged, map, shareReplay, startWith, switchMap, take, tap} from 'rxjs/operators';
import {OnDestroyMixin} from '@w11k/ngx-componentdestroyed';

import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';
import {FundraisingReqRes} from './fundraising/fundraisings-tab/FundraisingReqRes.model';
import {SearchOptionsRequest} from 'src/app/shared/models/SearchOptionsRequest.model';
import {GpHoldingServiceInterface} from './gp-holding-service.interface';
import {SearchOptionsResponse} from 'src/app/shared/models/SearchOptionsResponse.model';
import {DistributionTableItemResponse} from './distribution/DistributionTableItemResponse.model';
import {GpDistributionDataService} from 'src/app/services/gp/gp-distribution-data.service';
import HoldingDiscriminator from 'src/app/shared/enums/HoldingDiscriminator.enum';
import {GpHolding} from './GpHolding.model';
import {FundraisingTableItemResponse} from './fundraising/fundraisings-tab/FundraisingTableItemResponse.model';
import {GpFundraisingDataService} from 'src/app/services/gp/gp-fundraising-data.service';
import {HoldingUnderManagementTabs} from 'src/app/shared/types/GpTypes';
import {GpHoldingDataService} from 'src/app/services/gp/gp-holding-data.service';
import {InvestorContactReqRes} from '../../contacts/models/investorContactReqRes.model';
import {AppQuery} from 'src/app/state';
import {SortUiState} from 'src/app/account/my-account/model/ui-state/SortUiState.model';
import {UtilsService} from 'src/app/services/utils.service';
import {FundraisingStatus} from 'src/app/shared/enums/FundraisingStatus.enum';
import {InvestingEntityReqRes} from '../../models/InvestingEntityReqRes.model';
import {BankAccountTableRowDto} from '../../bankAccounts/models/externalClientBankAccountDto.model';
import {faker} from '@faker-js/faker';

@Injectable()
export abstract class GpHoldingService extends OnDestroyMixin implements GpHoldingServiceInterface {

  /** A list of the statuses that we treat as last status (for example, for asset investment it could be Invested or Declined) */
  readonly abstract FinalInvestmentStatuses: InvestmentStatus[];

  readonly abstract holdingDiscriminator: HoldingDiscriminator;
  private _distributionRefreshed$ = new Subject();
  public distributionRefreshed$ = this._distributionRefreshed$.asObservable();

  get discriminatorStr() {
    return HoldingDiscriminator.toString(this.holdingDiscriminator);
  }

  get discriminatorLowercaseStr() {
    return HoldingDiscriminator.toString(this.holdingDiscriminator).toLowerCase();
  }

  // Services
  private _route: ActivatedRoute;
  private _gpDistributionDataService: GpDistributionDataService;
  private _gpFundraisingDataService: GpFundraisingDataService;
  private _gpHoldingDataService: GpHoldingDataService;
  private _appQuery: AppQuery;
  private _utilsService: UtilsService;

  vcr: ViewContainerRef;

  holdingId$: Observable<number>;
  distributions$: Observable<DistributionTableItemResponse[]>;
  fundraisingSearchResponse$: Observable<SearchOptionsResponse<FundraisingTableItemResponse>>;
  fundraisings$: Observable<FundraisingTableItemResponse[]>;
  contactsWhoCanSeeHolding$: Observable<InvestorContactReqRes[]>;
  accessibleByInvestingEntities$: Observable<InvestingEntityReqRes[]>;

  creTransactionsTabVisible$: Subject<boolean> = new Subject();

  documentsChanged$ = new Subject<void>();
  searchOptionsFundraisings$: Observable<SearchOptionsRequest>;

  searchOptionsDistributions$: Observable<SearchOptionsRequest>;
  abstract holding$: ReplaySubject<GpHolding>;
  fundraising$: ReplaySubject<FundraisingReqRes> = new ReplaySubject<FundraisingReqRes>(1);

  /** True if the fundraising is Complete, and all investments in it were not done via Covercy. */
  isCompletedExternalContribution$: Observable<boolean>;
  isCompletedContribution$: Observable<boolean>;

  listOptions = {pageSize: 100, filter: ''} as SearchOptionsRequest;
  private distRefresh$ = new Subject<void>();
  contRefresh$ = new BehaviorSubject<void>(null);
  signersRefresh$ = new Subject<void>();

  constructor(
    _route: ActivatedRoute,
    _gpDistributionDataService: GpDistributionDataService,
    _gpFundraisingDataService: GpFundraisingDataService,
    _gpHoldingDataService: GpHoldingDataService,
    _appQuery: AppQuery,
    _utilsService: UtilsService
  ) {
    super();
    // Set the services
    this._route = _route;
    this._gpDistributionDataService = _gpDistributionDataService;
    this._gpFundraisingDataService = _gpFundraisingDataService;
    this._gpHoldingDataService = _gpHoldingDataService;
    this._appQuery = _appQuery;
    this._utilsService = _utilsService;

    this.initAllProperies();
  }

  get fundraisingBankAccountName$(): Observable<string> {
    return this.fundraising$.pipe(
      take(1),
      map(fundraising => {
        let bankAccountName = '';
        if (!!fundraising.clientBankAccount) {
          bankAccountName = fundraising.clientBankAccount.nickname;
        } else if (!!fundraising.unitBankAccount) {
          bankAccountName = fundraising.unitBankAccount.name;
        }

        return bankAccountName;
      })
    );
  }

  refreshDistribution() {
    this.distRefresh$.next();
  }

  refreshSigners() {
    this.signersRefresh$.next();
  }

  amountFunded(fundraisingDetails: FundraisingReqRes): number {
    const invested = fundraisingDetails.investments.filter(x => x.status === InvestmentStatus.Invested);
    if (invested.length > 0) {
      const totalInvestedAmount = invested.map(x => x.finalAmount).reduce((sum, current) => sum + current);
      return +totalInvestedAmount.toFixed(2);
    }
    return 0;
  }

  /** Returs true if all investments in the fundraising reached a final status */
  allInvestmentsAreDone(fundraising: FundraisingReqRes): boolean {
    if (fundraising && this.amountFunded(fundraising) >= fundraising.fundraisingTargetAmount) {
      const statusesOk = fundraising.investments.every(investment => !!this.FinalInvestmentStatuses.find(s => s === investment.status));
      if (statusesOk) {
        return true;
      }
    }
    return false;
  }

  abstract getDistributionPageRoute(holdingId: number, distributionId: number): string;

  abstract getCreateDistributionRoute(holdingId: number): string;

  abstract getImportDistributionRoute(holdingId: number): string;

  abstract getHoldingPageWithTabRoute(holdingId: number, tab: HoldingUnderManagementTabs): string;

  abstract holdingDownInfo(): void;

  abstract getFundraisingSearchOptionsState(state): SortUiState;

  abstract getDistributionSearchOptionsState(state): SortUiState;

  abstract updateHoldingFromServer(): void;

  // @ts-ignore
  abstract showEditHolding(vcr: ViewContainerRef, initialTabNumber: number, scrollToId: string = null);

  abstract showUpdateStatus();

  abstract discriminatorName: HoldingDiscriminator;

  private initAllProperies() {

    this.holdingId$ = this._route.params.pipe(
      distinctUntilChanged(),
      map(params => +params.id),
      shareReplay(1)
    );

    this.searchOptionsFundraisings$ = this._appQuery.gpUiPrefs.pipe(
      map(state => this.getFundraisingSearchOptionsState(state)),
      map(sort => ({orderBy: sort.orderBy, sortOrder: sort.direction} as SearchOptionsRequest))
    );

    this.searchOptionsDistributions$ = this._appQuery.gpUiPrefs.pipe(
      map(state => this.getDistributionSearchOptionsState(state)),
      map(sort => ({orderBy: sort.orderBy, sortOrder: sort.direction} as SearchOptionsRequest))
    );

    this.fundraisingSearchResponse$ = combineLatest([this.searchOptionsFundraisings$, this.contRefresh$]).pipe(
      switchMap(([searchOptionState, _]) => this.getFundraisingsList(searchOptionState))
    );

    this.fundraisings$ = this.fundraisingSearchResponse$.pipe(
      map(response => response.rows),
    );

    this.distributions$ = combineLatest([this.searchOptionsDistributions$, this.distRefresh$.pipe(startWith(''))])
      .pipe(
        switchMap(([uiState, _]) => this.getDistributionListFromServer(uiState)),
        map(response => response.rows),
        tap(_ => this._distributionRefreshed$.next(undefined)),
        shareReplay(1)
      );

    this.contactsWhoCanSeeHolding$ = this.holdingId$.pipe(
      switchMap(holdingId => this._gpHoldingDataService.getContactsWhoCanSeeTheHoldingInInvestorPortal(holdingId)),
      shareReplay(1)
    ); // DEPRECATED

    this.accessibleByInvestingEntities$ = this.holdingId$.pipe(
      switchMap(holdingId => this._gpHoldingDataService.getAccessibleByInvestingEntities(holdingId)),
      shareReplay(1)
    );

    this.isCompletedExternalContribution$ = this.fundraising$.pipe(
      map(fundraising =>
        fundraising.status === FundraisingStatus.Completed && fundraising.investments && fundraising.investments.every(i => !i.isOrderCreated)),
      shareReplay(1));

    this.isCompletedContribution$ = this.fundraising$.pipe(
      map(fundraising =>
        fundraising.status === FundraisingStatus.Completed),
      shareReplay(1));


    // this.creTransactionsTabVisible$ = new Subject();
  }


  private getDistributionListFromServer(options: SearchOptionsRequest = null): Observable<SearchOptionsResponse<DistributionTableItemResponse>> {
    if (!options) {
      options = this.listOptions;
    }
    options.filter = options.filter || '';

    return this.holdingId$.pipe(
      switchMap(assetId => this._gpDistributionDataService.getList(assetId, options))
    );
  }

  private getFundraisingsList(options: SearchOptionsRequest = null): Observable<SearchOptionsResponse<FundraisingTableItemResponse>> {
    if (!options) {
      options = this.listOptions;
    }
    options.filter = options.filter || '';

    return this.holdingId$.pipe(
      switchMap(holdingId => this._gpFundraisingDataService.getList(holdingId, options)),
      shareReplay());
  }

  /**
   * Update only the offering deck in the fundraising
   * @param updatedFundraising Updated fundraising with updated offering deck info
   */
  public onUpdatingOfferingDeck(updatedFundraising: FundraisingReqRes) {
    this.fundraising$.pipe(
      take(1)
    ).subscribe(fundraisingBeforeUpdate => {
      this.fundraising$.next({
        ...fundraisingBeforeUpdate,
        offeringDeckId: updatedFundraising.offeringDeckId,
        offeringDeck: updatedFundraising.offeringDeck
      });
    });
  }

  public generateRandomBalancesForAccounts(accounts: BankAccountTableRowDto[]): BankAccountTableRowDto[] {
    const minRandom = 100000;
    const maxRandom = 10000000;
    accounts.forEach(a => a.balance = faker.number.int({min: minRandom, max: maxRandom}));
    return accounts;
  }

  public generateRandomNamesForAccounts(accounts: BankAccountTableRowDto[]): BankAccountTableRowDto[] {
    accounts.forEach(a => a.accountNickname = faker.company.name());
    return accounts;
  }
}
