import { AbstractControl, UntypedFormControl, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import InvestmentStatus from 'src/app/shared/enums/InvestmentStatus.enum';

export class CustomErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return !!(control && control.invalid && !control.errors?.matDatepickerMin);
    }
}


export function investmentStatusValidation(investmentStatus: InvestmentStatus): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const invalid = {invalidInvestmentStatus: true};
      const value: InvestmentStatus = control.value;
      if (value === investmentStatus){
        return null;
      }
      return invalid;
    };
  }
