import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, of, shareReplay, switchMap } from 'rxjs';
import { HttpService } from 'src/app/services/http.service';
import { AbstractRestService } from 'src/app/shared/types/AbstractRestService';
import { InvestingEntityCommitmentInvestmentTotals } from './models/investingEntityCommitmentInvestmentTotals.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GpHoldingService } from '../gp-holding.service';
import { InvestingEntityCommitmentTotals } from './models/investingEntityCommitmentTotals.model';

// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class InvestorsManagementService extends AbstractRestService<any, any> {

  protected readonly baseEndpoint$ = of('holdings/');

  constructor(public http: HttpService,
    private activatedRoute: ActivatedRoute,
  ) {
    super(http);
   }

   getAllInvestorsManagement(holdingId:number):Observable<InvestingEntityCommitmentInvestmentTotals>{
    return this.baseEndpoint$.pipe(
      switchMap(baseEndpoint => this.http.getTyped<InvestingEntityCommitmentInvestmentTotals>(`${baseEndpoint}${holdingId}/fundraisings/totals`))
    )
   }

   getTotalCommitmentsForHolding(holdingId:number):Observable<number>{
    return this.baseEndpoint$.pipe(
      switchMap(baseEndpoint => this.http.getTyped<number>(`${baseEndpoint}${holdingId}/fundraisings/total-commitments`))
    )
   }

   saveTotals(holdingId:number, data:{totals:InvestingEntityCommitmentTotals[]}):Observable<any>{
    return this.baseEndpoint$.pipe(
      switchMap(baseEndpoint => this.http.postTyped<any>(`${baseEndpoint}${holdingId}/total-commitments`, data))
    )
   }
}


