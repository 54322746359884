export enum InvestmentStatus {
  Potential = 1,
  SoftCircled = 2,
  Signed = 3,
  Invested = 4,
  Declined = 5
}

// tslint:disable-next-line:no-namespace
export namespace InvestmentStatus {
  export function toString(investmentStatus: InvestmentStatus): string {
    switch (investmentStatus) {
      case InvestmentStatus.Invested:
        return 'Invested';
      case InvestmentStatus.Signed:
        return 'Signed';
      case InvestmentStatus.SoftCircled:
        return 'Soft Circled';
      case InvestmentStatus.Potential:
        return 'Potential';
      case InvestmentStatus.Declined:
        return 'Declined';
      default:
        return null;
    }
  }

  export function parse(investmentStatusStr: string): InvestmentStatus {
    return InvestmentStatus[investmentStatusStr];
  }
}

export default InvestmentStatus;
