export enum FundraisingStatus {
  None = 0,
  Canceled = 1,
  InProgress = 2,
  Completed = 3
}

// tslint:disable-next-line:no-namespace
export namespace FundraisingStatus {
  export function toString(fundraisingStatus: FundraisingStatus): string {
    switch (fundraisingStatus) {
      case FundraisingStatus.InProgress:
        return 'In Progress';
      case FundraisingStatus.Completed:
        return 'Completed';
      case FundraisingStatus.Canceled:
        return 'Canceled';
      default:
        return null;
    }
  }

  export function parse(fundraisingStatusStr: string): FundraisingStatus {
    return FundraisingStatus[fundraisingStatusStr];
  }
}

export default FundraisingStatus;
