import { Observable, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

export interface BlockNavigationIfHasChanges {
  hasChanges: boolean;
  openConfirmationModal(): Observable<boolean>;
}

@Injectable({ providedIn: 'root' })
export class CanDeactivateNavigationIfHasChanges<
  T extends BlockNavigationIfHasChanges
> 
{
  canDeactivate(
    component: T,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return new Observable<boolean>((observer) => {
      if (component.hasChanges) {
        return component
          .openConfirmationModal()
          .subscribe((confirmation: boolean) => {
            if (confirmation) {
              observer.next(confirmation);
              observer.complete();
            }
          });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }
}
